<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">전화 상담이력조회</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class>
          <div class="form-inp sm">
            <v-select
              v-model="selected_channel"
              :items="asp_new_cust_Key"
              item-value="CD"
              item-text="CD_NM"
              outlined
              hide-details
              label="회사구분"
              v-on:change="[chnASP(), getCmmCode(), cngSelMenu(), getUserGroup()]"
              :readonly="RDOnly"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            content-class="calendar-modal"
            color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
                hide-details
                class="form-inp icon-calendar ml-2"
                append-icon="svg-calendar"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                v-model="dates[0]"
                no-title
                dark
                color="#F7AFAE"
                name="startDate"
                @change="startDate"
                :first-day-of-week="1"
                locale="ko-KR"
                :day-format="mixin_getDate"
              ></v-date-picker>
              <v-date-picker
                v-model="dates[1]"
                dark
                color="#F7AFAE"
                no-title
                show-current="false"
                :min="dates[0]"
                name="endDate"
                @change="endDate"
                :first-day-of-week="1"
                locale="ko-KR"
                :day-format="mixin_getDate"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn outlined class="btn-default" @click="menu1 = false"
                >확인</v-btn
              >
            </div>
          </v-menu>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="calltypcd"
              :items="mixin_common_code_get(this.common_code, 'PLT025', '전체')"
              outlined
              hide-details
              label="콜유형"
              filled
              dense
              background-color="white"
            ></v-select>
          </div>
          <!--
          <div class="form-inp sm ml-2">
            <v-select
              v-model="cnsltypcd"
              :items="srhCnslTypCd"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="상담유형1"
              @change="changeCounselTypeKey(selected_channel, cnsltypcd, '1')"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="cnsltypcd2"
              :items="srhCnslTypCd2"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="상담유형2"
              @change="changeCounselTypeKey(selected_channel, cnsltypcd2, '2')"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="cnsltypcd3"
              :items="srhCnslTypCd3"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="상담유형3"
              @change="changeCounselTypeKey(selected_channel, cnsltypcd3, '3')"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="cnsltypcd4"
              :items="srhCnslTypCd4"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="상담유형4"
              placeholder="선택하세요"
            ></v-select>
          </div>
          -->
          <div class="form-inp sm ml-2">
            <v-select
              v-if = "SEL_1"
              v-model="MODEL_1"
              :disabled="!SEL_1"
              :items="ITEM_1"
              :item-text="ITEM_TEXT_1"
              :item-value="ITEM_VALUE_1"
              outlined
              hide-details
              :label="TEXT_1"
              placeholder="선택하세요"
              @change="getConsultTY('1')"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-if = "SEL_2"
              v-model="MODEL_2"
              :disabled="!SEL_2"
              :items="ITEM_2"
              :item-text="ITEM_TEXT_2"
              :item-value="ITEM_VALUE_2"
              outlined
              hide-details
              :label="TEXT_2"
              placeholder="선택하세요"
              @change="getConsultTY('2')"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-if = "SEL_3"
              v-model="MODEL_3"
              :disabled="!SEL_3"
              :items="ITEM_3"
              :item-text="ITEM_TEXT_3"
              :item-value="ITEM_VALUE_3"
              outlined
              hide-details
              :label="TEXT_3"
              placeholder="선택하세요"
              @change="getConsultTY('3')"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-if = "SEL_4"
              v-model="MODEL_4"
              :disabled="!SEL_4"
              :items="ITEM_4"
              :item-text="ITEM_TEXT_4"
              :item-value="ITEM_VALUE_4"
              outlined
              hide-details
              :label="TEXT_4"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-if = "SEL_5"
              v-model="MODEL_5"
              :disabled="!SEL_5"
              :items="ITEM_5"
              :item-text="ITEM_TEXT_5"
              :item-value="ITEM_VALUE_5"
              outlined
              hide-details
              :label="TEXT_5"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-if = "SEL_6"
              v-model="MODEL_6"
              :disabled="!SEL_6"
              :items="ITEM_6"
              :item-text="ITEM_TEXT_6"
              :item-value="ITEM_VALUE_6"
              outlined
              hide-details
              :label="TEXT_6"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-if = "SEL_7"
              v-model="MODEL_7"
              :disabled="!SEL_7"
              :items="ITEM_7"
              :item-text="ITEM_TEXT_7"
              :item-value="ITEM_VALUE_7"
              outlined
              hide-details
              :label="TEXT_7"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-if = "SEL_8"
              v-model="MODEL_8"
              :disabled="!SEL_8"
              :items="ITEM_8"
              :item-text="ITEM_TEXT_8"
              :item-value="ITEM_VALUE_8"
              outlined
              hide-details
              :label="TEXT_8"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="mt-2">
            <!-- 고객조회조건 변경
            <v-text-field
              class="form-inp sm"
              name="userId"
              v-model="userId"
              label="고객 ID"
              outlined
              hide-details
            ></v-text-field>
            -->
            <v-text-field
              class="form-inp sm"
              name="userPhoneNo"
              v-model="userPhoneNo"
              label="고객전화번호"
              outlined
              hide-details
            ></v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="userNm"
              v-model="userNm"
              label="고객 이름"
              outlined
              hide-details
            ></v-text-field>
            <!-- 조회 버튼 -->            
            <v-btn
              v-if="this.mixin_set_btn(this.$options.name, 'btnPopupCus')"
              dark
              small
              class="btn-main-search pa-0 ml-2"
              min-width="32px"
              height="32px"
              plain
              @click="btnCus(), showDialog('MP01')"
            >
              <v-icon small class="svg-main-searh white"></v-icon>
            </v-btn>
            <!-- 새로고침 버튼 -->
            <v-btn
              v-if="this.mixin_set_btn(this.$options.name, 'btnInitCus')"
              dark
              small
              class="btn-main-refresh pa-0 ml-2"
              min-width="32px"
              height="32px"
              @click="clear0"
              plain
            >
              <v-icon small class="svg-main-refresh"></v-icon>
            </v-btn>
            <div class="form-inp free ml-2">
              <v-select
                single-line
                v-model="selected_userGroup"
                :items="userGroup"
                item-value="CD"
                item-text="CD_NM"
                outlined
                label="팀"
                multiple
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 1" small>
                    <span>{{ item.CD_NM }}</span>
                    <template>
                      <v-btn
                        icon
                        x-small
                        class="svg-close20 ml-1"
                        v-show="dropItemRemove === true"
                        @click.stop="removeDropGroup(index)"
                      ></v-btn>
                    </template>
                  </v-chip>
                  <span v-if="index === 0" class="grey--text text-caption">
                    ...
                  </span>
                </template>
              </v-select>
            </div>
            <v-text-field
              class="form-inp sm ml-2"
              name="userId1"
              v-model="userId1"
              label="사용자 ID"
              outlined
              hide-details
            ></v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="userNm1"
              v-model="userNm1"
              label="사용자 이름"
              outlined
              hide-details
            ></v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="userNick1"
              v-model="userNick1"
              label="사용자 닉네임"
              outlined
              hide-details
            ></v-text-field>
            <!-- 조회 버튼 -->
            <v-btn
              v-if="this.mixin_set_btn(this.$options.name, 'btnPopupUser')"
              dark
              small
              class="btn-main-search pa-0 ml-2"
              min-width="32px"
              height="32px"
              plain
              @click="btnUser(), showDialog('MP02')"
            >
              <v-icon small class="svg-main-searh white"></v-icon>
            </v-btn>
            <!-- 새로고침 버튼 -->
            <v-btn
              v-if="this.mixin_set_btn(this.$options.name, 'btnInitUser')"
              dark
              small
              class="btn-main-refresh pa-0 ml-2"
              min-width="32px"
              height="32px"
              @click="clear1"
              plain
            >
              <v-icon small class="svg-main-refresh"></v-icon>
            </v-btn>
          <div class="form-inp sm ml-2">
            <v-select
              v-if = "SEL_9"
              v-model="MODEL_9"
              :disabled="!SEL_9"
              :items="ITEM_9"
              :item-text="ITEM_TEXT_9"
              :item-value="ITEM_VALUE_9"
              outlined
              hide-details
              :label="TEXT_9"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-if = "SEL_10"
              v-model="MODEL_10"
              :disabled="!SEL_10"
              :items="ITEM_10"
              :item-text="ITEM_TEXT_10"
              :item-value="ITEM_VALUE_10"
              outlined
              hide-details
              :label="TEXT_10"
              placeholder="선택하세요"
            ></v-select>
          </div>
          </div>
          <div class="form-inp free">
            <div class="form-inp free">
              <v-select
                single-line
                v-model="CHK_NEWTIME"
                :items="CHK_TIME"
                item-value="value"
                item-text="text"
                outlined
                label="상담일자"
                multiple
                hide-details
                v-on:change="[chgChkTime(),chkNoTime()]"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 4" small>
                    <span>{{ item.text }}</span>
                    <template>
                      <v-btn
                        icon
                        x-small
                        class="svg-close20 ml-1"
                        v-show="dropItemRemove === true"
                        @click.stop="removeDropTime(item)"
                      ></v-btn>
                    </template>
                  </v-chip>
                  <span v-if="index === 3" class="grey--text text-caption">
                    ...
                  </span>
                </template>
              </v-select>
            </div>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAllCheck')" outlined class="btn-default ml-2" @click="allClick"
              >전체선택</v-btn
            >
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAllUncheck')" outlined class="btn-default ml-2" @click="[allCloseClick(),chkNoTime()]"
              >전체해제</v-btn
            >
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnWork')" outlined class="btn-default ml-2" @click="selectTime"
              >업무시간선택</v-btn
            >
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnTurn')" outlined class="btn-default ml-2" @click="selectTurn"
              >선택반전</v-btn
            >

          </div>
            <div class="form-inp free ml-2">
            <v-text-field
              class="form-inp sm ml-2"
              name="CALL_TIME"
              v-model="CALL_TIME"
              label="녹음시간"
              suffix="초"
              outlined
              hide-details
            ></v-text-field>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                v-model="SEL_NEWUPDOWN"
                :items="SEL_UPDOWN"
                item-value="CD"
                item-text="CD_NM"
                outlined
                hide-details
                label="범위"
              >
                <template v-slot:label>
                  범위
                </template>
              </v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                class="form-inp full"
                :items="CNSL_RESULT_LIST"
                outlined
                hide-details
                placeholder="선택하세요"
                v-model="SRH_RESULT"
                >
                <template v-slot:label>
                  상담결과
                </template>
              </v-select>
            </div>
        </div>
        <div class="ml-auto align-self-center">
        <v-btn
        :disabled = "nextDisabled"
              dark
              small
              class="btn-main-search pa-0 ml-2"
              min-width="32px"
              height="32px"
              plain
              @click="search('N', 'next')"
            >다음</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2 ml-3" v-on:click="search('N')">조회</v-btn>
        </div>
      </div>
    </div>
    <!-- 상담이력조회 목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        상담이력조회 목록
        <div class="ml-auto">
          <v-text-field
            class="form-inp sm ml-2"
            name="SEL_DATA_NUM"
            v-model="SEL_DATA_NUM"
            label="엑셀 데이터 개수"
            outlined
            hide-details
          ></v-text-field>
          <v-btn
            v-if="this.mixin_set_btn(this.$options.name, 'btnExcelDown')"
            outlined
            small
            class="btn-point ml-2"
            @click="search('Y')"
          >
            <v-icon small>mdi-arrow-collapse-down</v-icon>엑셀다운로드
          </v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <v-data-table
          dense
          height="320px"
          :headers="gridDataHeaders"
          :items="gridDataText"
          single-select
          item-key="ROW_NUMBER"
          :items-per-page="itemsPerPage"
          :page.sync="page"
          hide-default-footer
          class="grid-default"
          @page-count="pageCount = $event"
          @click:row="showRowInfo"
          :item-class="isActiveRow"
          fixed-header
        >
          <template v-slot:item.BTN_01="{ item }">
            <!--
              버튼권한을 줄 수 없음
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnHear')" outlined small class="btn-point ml-2">녹취</v-btn> 
            -->
            <v-btn outlined small class="btn-point ml-2">녹취</v-btn> 
          </template>
          <template v-slot:item.CNSL_DATE="{ item }">
            <div>{{ dateFormat(item.CNSL_DATE) }}</div>
          </template>
          <template v-slot:item.CNSL_BEGIN_TIME="{ item }">
            <div>{{ timeFormat(item.CNSL_BEGIN_TIME) }}</div>
          </template>
          <template v-slot:item.CNSL_EOT_TIME="{ item }">
            <div>{{ timeFormat(item.CNSL_EOT_TIME) }}</div>
          </template>
          <template v-slot:item.CUST_NO="{ item }">
            <div>{{ juminFormat(item.CUST_NO) }}</div>
          </template>
          <template v-slot:item.CUST_TEL_NO="{ item }">
            <div>{{ phoneHyphen(item.CUST_TEL_NO) }}</div>
          </template>
          
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>

        <!--다운로드 받을 테이블-->
        <v-data-table
          dense
          height="0px"
          :headers="gridDataHeaders"
          :items="downGridDataText"
          single-select
          item-key="ROW_NUMBER"
          :items-per-page="SEL_DATA_NUM == '' ? pagination.rowsPerPage : SEL_DATA_NUM"
          :page.sync="page"
          hide-default-footer
          class="grid-default"
          id="downTable"
        >
        </v-data-table>
        <!--보이지 않는 테이블-->

      </div>
    </div>
    <!--// 상담이력조회 목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">녹취재생</h2>
      <v-card-text>
        <v-row class="black--text">
          <span v-if="RDWT_FILE_NM == '' || RDWT_FILE_NM ==null">녹취파일이 없거나 파일을 재생할 수 없습니다.</span>
          <audio 
            v-if="RDWT_FILE_NM != '' && RDWT_FILE_NM !=null"
            controls="" 
            :autoplay="false"
            name="media"
            :src="REC_CALL"
            type="audio/mpeg"
          >
          </audio>
        </v-row>
      </v-card-text>
    </div>
    <!-- 상담이력상세정보 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">상담이력 상세정보</h2>
      <div class="box-ct">
        <div class="table-form">
          <table  v-if="selected_channel != 'FUNU'">
            <caption class="hide">
              상담이력 상세정보
            </caption>
            <colgroup>
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">상담시작일자</th>
                <td>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-calendar"
                        disabled
                        :value="dateFormat(contactDetail.CNSL_DATE)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#F7AFAE"
                      no-title
                      @input="menu2 = false"
                      disabled
                      :first-day-of-week="1"
                      locale="ko-KR"
                      :day-format="mixin_getDate"
                    ></v-date-picker>
                  </v-menu>
                </td>
                <th scope="row">상담시작시간</th>
                <td>
                  <v-menu
                    v-model="menu5"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-time"
                        disabled
                        :value="timeFormat(contactDetail.CNSL_BEGIN_TIME)"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timeStep"
                      :allowed-minutes="allowedStep"
                      color="#F7AFAE"
                      format="24hr"
                      @input="menu5 = false"
                      disabled
                    ></v-time-picker>
                  </v-menu>
                </td>
                <th scope="row">콜유형</th>
                <td>
                  <v-text-field
                    v-model="contactDetail.CALL_TY_NM"
                    class="form-inp full"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <th scope="row">고객전화번호</th>
                <td>
                  <v-text-field
                    class="form-inp sm"
                    :disabled="true"
                    outlined
                    hide-details
                    :value="phoneHyphen(contactDetail.CUST_TEL_NO)"
                  ></v-text-field>
                </td>
                <th scope="row">발신번호</th>
                <td>
                  <v-text-field
                    class="form-inp sm"
                    v-model="contactDetail.SEND_MAN_NO"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">상담종료일자</th>
                <td>
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-calendar"
                        disabled
                        :value="dateFormat(contactDetail.CNSL_EOT_DATE)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#F7AFAE"
                      no-title
                      @input="menu3 = false"
                      disabled
                      :first-day-of-week="1"
                      locale="ko-KR"
                      :day-format="mixin_getDate"
                    ></v-date-picker>
                  </v-menu>
                </td>
                <th scope="row">상담종료시간</th>
                <td>
                  <v-menu
                    v-model="menu5"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-time"
                        disabled
                        :value="timeFormat(contactDetail.CNSL_EOT_TIME)"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timeStep"
                      :allowed-minutes="allowedStep"
                      color="#F7AFAE"
                      format="24hr"
                      @input="menu5 = false"
                      disabled
                    ></v-time-picker>
                  </v-menu>
                </td>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.CSLT_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  >
                    <template v-slot:label>
                      사용자명
                    </template>
                  </v-text-field>
                </td>
                <td>
                  <v-select
                    class="form-inp full"
                    :items="CNSL_RESULT_LIST"
                    outlined
                    hide-details
                    placeholder="선택하세요"
                    v-model="CNSL_RESULT"
                    :disabled="true"
                  >
                    <template v-slot:label>
                      상담결과
                    </template>
                  </v-select>
                </td>
                <th scope="row" rowspan="2">특이사항<br>/비고</th>
                <td rowspan="2" colspan="3">
                  <v-textarea
                    class="textarea-type0"
                    :value="contactDetail.ETC"
                    v-model="ETC"
                    outlined
                    hide-details
                    no-resize
                    :disabled="true"
                  ></v-textarea>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  문의내용
                </th>
                <td><v-select
                    class="form-inp full"
                    :items="CNSL_CONT_LIST"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="CNSL_CONT"
                    :disabled="true"
                  >
                    <template v-slot:label>
                      문의내용
                    </template>
                  </v-select></td>
                <td>
                  <v-select
                    class="form-inp full"
                    :items="VIS_PATH_LIST"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="VIS_PATH"
                    :disabled="true"
                  >
                    <template v-slot:label>
                      내원경로
                    </template>
                  </v-select>
                </td>
                <td colspan="3">
                  <v-select
                    class="form-inp sm"
                    :items="VIS_BOOK_LIST"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="VIS_BOOK"
                    :disabled="true"
                  >
                    <template v-slot:label>
                      상담내원
                    </template>
                  </v-select>
                  <v-text-field
                    class="form-inp sm ml-2"
                    v-model="contactDetail.VIS_BOOK_DT"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                  <v-select
                    class="form-inp sm ml-2"
                    :items="VIS_MANAGE_LIST"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="VIS_MANAGE"
                    :disabled="true"
                  >
                    <template v-slot:label>
                      상담내원담당
                    </template>
                  </v-select>
                </td>
              </tr>
              <tr>
                <th scope="row">진료항목</th>
                <td>
                  <v-select
                    class="form-inp full"
                    :items="DISA_ITEM_LIST"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="DISA_ITEM"
                    :disabled="true"
                  >
                    <template v-slot:label>
                      진료항목
                    </template>
                  </v-select>
                </td>
                <td>
                  <v-select
                    class="form-inp full"
                    :items="CONDITION_LIST"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="CONDITION"
                    :disabled="true"
                  >
                    <template v-slot:label>
                      상태
                    </template>
                  </v-select>
                </td>
                <td colspan="3">
                  <v-select
                    class="form-inp sm"
                    :items="OPER_BOOK_LIST"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="OPER_BOOK"
                    :disabled="true"
                  >
                    <template v-slot:label>
                      수술예약
                    </template>
                  </v-select>
                  <v-text-field
                    class="form-inp sm ml-2"
                    v-model="contactDetail.OPER_BOOK_DT"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                  <v-select
                    class="form-inp sm ml-2"
                    :items="OPER_MANAGE_LIST"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="OPER_MANAGE"
                    :disabled="true"
                  >
                    <template v-slot:label>
                      수술예약담당
                    </template>
                  </v-select>
                </td>
                <th scope="row" rowspan="2">상담내용</th>
                <td rowspan="2" colspan="3">
                  <v-textarea
                    class="textarea-type0"
                    label
                    :value="contactDetail.CNSL_CNTN"
                    v-model="CNSL_CNTN"
                    no-resize
                    outlined
                    :disabled="true"
                  ></v-textarea>
                </td>
              </tr>
              <tr>
                <th scope="row">상담예약</th>
                <td scope="row">
                <v-select
                    class="form-inp full"
                    :items="CNSL_BOOK_LIST"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="CNSL_BOOK"
                    :disabled="true"
                  >
                    <template v-slot:label>
                      문의예약
                    </template>
                  </v-select>
                </td>
                <td>
                  <v-text-field
                    class="form-inp icon-calendar ml-2 pt-0"
                    v-model="contactDetail.CNSL_BOOK_DT"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <td colspan="2">
                  <v-select
                    class="form-inp sm"
                    :items="OPERATION_LIST"
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="OPERATION"
                    :disabled="true"
                  >
                    <template v-slot:label>
                      수술
                    </template>
                  </v-select>
                  <v-text-field
                    class="form-inp sm ml-2"
                    v-model="contactDetail.OPERATION_DT"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    class="form-inp sm"
                    outlined
                    hide-details
                    value=""
                    label="수술금액"
                    v-model="OPERATION_COST"
                    :disabled="true"
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </table>
          <table v-if="selected_channel == 'FUNU'">
            <caption class="hide">
              상담이력 상세정보
            </caption>
            <colgroup>
              <col width="127px" />
              <col width="226px" />
              <col width="127px" />
              <col width="226px" />
              <col width="127px" />
              <col width="226px" />
              <col width="127px" />
              <col width="226px" />
              <col width="127px" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">상담시작일자</th>
                <td>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-calendar"
                        disabled
                        :value="dateFormat(contactDetail.CNSL_DATE)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#F7AFAE"
                      no-title
                      @input="menu2 = false"
                      disabled
                      :first-day-of-week="1"
                      locale="ko-KR"
                      :day-format="mixin_getDate"
                    ></v-date-picker>
                  </v-menu>
                </td>
                <th scope="row">상담시작시간</th>
                <td>
                  <v-menu
                    v-model="menu5"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-time"
                        disabled
                        :value="timeFormat(contactDetail.CNSL_BEGIN_TIME)"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timeStep"
                      :allowed-minutes="allowedStep"
                      color="#F7AFAE"
                      format="24hr"
                      @input="menu5 = false"
                      disabled
                    ></v-time-picker>
                  </v-menu>
                </td>
                <th scope="row">콜유형</th>
                <td>
                  <v-select
                    class="form-inp sm"
                    :items="
                      mixin_common_code_get(this.common_code, 'PLT007', '전체')
                    "
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="contactDetail.CALL_TY"
                    readonly
                  ></v-select>
                </td>
                <th scope="row">고객전화번호</th>
                <td>
                  <v-text-field
                    class="form-inp sm"
                    :disabled="true"
                    outlined
                    hide-details
                    :value="phoneHyphen(contactDetail.CUST_TEL_NO)"
                  ></v-text-field>
                </td>
                <th scope="row">발신번호</th>
                <td>
                  <v-text-field
                    class="form-inp sm"
                    v-model="contactDetail.SEND_MAN_NO"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">상담종료일자</th>
                <td>
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-calendar"
                        disabled
                        :value="dateFormat(contactDetail.CNSL_EOT_DATE)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#F7AFAE"
                      no-title
                      @input="menu3 = false"
                      disabled
                      :first-day-of-week="1"
                      locale="ko-KR"
                      :day-format="mixin_getDate"
                    ></v-date-picker>
                  </v-menu>
                </td>
                <th scope="row">상담종료시간</th>
                <td>
                  <v-menu
                    v-model="menu5"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-time"
                        disabled
                        :value="timeFormat(contactDetail.CNSL_EOT_TIME)"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timeStep"
                      :allowed-minutes="allowedStep"
                      color="#F7AFAE"
                      format="24hr"
                      @input="menu5 = false"
                      disabled
                    ></v-time-picker>
                  </v-menu>
                </td>
                <th scope="row">사용자명</th>
                <td>
                  <v-text-field
                    class="form-inp sm"
                    v-model="contactDetail.CSLT_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <th>녹취파일명</th>
                <td colspan="3">
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.RDWT_FILE_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  상담유형1
                </th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    outlined
                    hide-details
                    label="상담유형1"
                    placeholder="상담유형1"
                    v-model="CNSL_LV_1_DET"
                    :disabled="true"
                  ></v-text-field>
                </td>
                <th scope="row">
                  상담유형2
                </th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    outlined
                    hide-details
                    label="상담유형2"
                    placeholder="상담유형2"
                    v-model="CNSL_LV_2_DET"
                    :disabled="true"
                  ></v-text-field>
                </td>
                <th scope="row">
                  상담유형3
                </th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    outlined
                    hide-details
                    label="상담유형3"
                    placeholder="상담유형3"
                    v-model="CNSL_LV_3_DET"
                    :disabled="true"
                  ></v-text-field>
                </td>
                <th scope="row" rowspan="2">특이사항<br>/ 비고</th>
                <td rowspan="2" colspan="3">
                  <!-- <v-textarea
                    counter
                    label
                    id="detailCnslCntn"
                    v-model="rowData.CNSL_CNTN"

                    no-resize outlined
                  ></v-textarea> -->
                  <v-textarea
                    class="textarea-type0"
                    label
                    :value="contactDetail.ETC"
                    v-model="ETC"
                    no-resize
                    outlined
                    :disabled="true"
                  ></v-textarea>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  상담유형4
                </th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    outlined
                    hide-details
                    label="상담유형4"
                    placeholder="상담유형4"
                    v-model="CNSL_LV_4_DET"
                    :disabled="true"
                  ></v-text-field>
                </td>
                <th scope="row">
                </th>
                <td>
                </td>
                <th scope="row">
                </th>
                <td>
                </td>
              </tr>
              <tr>
                <th scope="row"></th>
                <td></td>
                <th scope="row">
                </th>
                <td>
                </td>
                <th scope="row">
                </th>
                <td>
                </td>
                <th scope="row" rowspan="2">상담내용</th>
                <td colspan="3" rowspan="2">
                  <v-textarea
                    class="textarea-type0"
                    label
                    :value="contactDetail.CNSL_CNTN"
                    v-model="CNSL_CNTN"
                    no-resize
                    outlined
                    :disabled="true"
                  ></v-textarea>
                </td>
              </tr>
              <tr>
                <th scope="row"></th>
                <td></td>
                <th scope="row">
                </th>
                <td>
                </td>
                <th scope="row">
                </th>
                <td>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--// 고객상세정보 -->
    <div calss>
      <v-dialog
        max-width="1400"
        max-height="1400"
        v-model="dialogMP02"
        content-class="square-modal"
      >
        <dialog-MP02
          headerTitle="사용자정보"
          @hide="hideDialog('MP02')"
          :userInfo="userInfo"
          @input="userInfo = $event"
          @selectUser="[hideDialog('MP02'), setUser()]"
        ></dialog-MP02>
        <template v-slot:body></template>
      </v-dialog>
    </div>

    <div calss>
      <v-dialog
        max-width="1400"
        max-height="1400"
        v-model="dialogMP01"
        content-class="square-modal"
      >
        <dialog-MP01
          headerTitle="고객정보"
          @hide="hideDialog('MP01')"
          :cusInfo="cusInfo"
          @input="cusInfo = $event"
          @selectCus="[hideDialog('MP01'), setCus()]"
        ></dialog-MP01>
        <template v-slot:body></template>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import XLSX from "xlsx";
import axios from "axios";
import { mapGetters } from "vuex";
import dialogMP01 from "./MP01.vue";
import dialogMP02 from "./MP02.vue";
import api from "../../store/apiUtil.js";

export default {
  name: "MENU_M110201", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    dialogMP01,
    dialogMP02,
  },
  data() {
    return {
      nextDisabled: true, //다음버튼 활성화 여부
      dropItemRemove: true, //사용자그룹 셀렉트
      dialogMP01: false,
      dialogMP02: false,
      userInfo: {},
      cusInfo: {},
      userGroup: [], //사용자그룹
      params: { userId: "", userPhoneNo: '',userNm: "", aspNewcustKey: "" },
      common_code: [],
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      timeStep: "10:10",
      //asp_cust_key: "HKCTALKMNG",
      selected_channel: "",
      search_channel: "",
      selected_userGroup: [],
      calltypcd: "",
      cnsltypcd: "",
      cnsltypcd2: "",
      cnsltypcd3: "",
      cnsltypcd4: "",
      userId: "",
      userPhoneNo:'',
      userNm: "",
      userId1: "",
      userNm1: "",
      userNick1: "",
      asp_new_cust_Key: this.$store.getters["userStore/GE_USER_ROLE"].company,
      srhCallTypCd: [], //콜유형
      srhInqryTypCd: [], //문의유형1
      srhInqryTypCd2: [],
      srhCnslTypCd: [
        {
          CD: "",
          CD_NM: "전체",
        },
      ], //상담유형
      srhCnslTypCd2: [
        {
          CD: "",
          CD_NM: "전체",
        },
      ], //상담유형2
      srhCnslTypCd3: [
        {
          CD: "",
          CD_NM: "전체",
        },
      ], //상담유형3
      srhCnslTypCd4: [
        {
          CD: "",
          CD_NM: "전체",
        },
      ], //상담유형4
      contactDetail: [], //상담내용저장
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],

      gridDataHeaders: [
        { align: "center", width: "20px", sortable: false },
        {
          text: "NO",
          value: "ROW_NUMBER",
          align: "center",
          width: "30px",
          sortable: false,
        },
        {
          text: "녹취청취",
          value: "BTN_01",
          align: "center",
          sortable: false,
          width: "100px",
        },
        //{ text: "상담이력번호", value: "CNSL_HIST_NO", align: " d-none", width: "0px", sortable: false },
        //{ text: "상담이력상세번호", value: "CNSL_HIST_DTL_NO", align: " d-none", width: "30px", sortable: false, },
        //{ text: "녹취ID", value: "RDWT_ID", align: " d-none", width: "30px", sortable: false, },
        {
          text: "상담일자",
          value: "CNSL_DATE",
          align: "center",
          sortable: true,
          width: "100px",
        },
        //{ text: "상담시작일자", value: "CNSL_BEGIN_DATE", align: " d-none", sortable: true, width: "100px", },
        {
          text: "콜 시작시간",
          value: "CNSL_BEGIN_TIME",
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "콜 종료시간",
          value: "CNSL_EOT_TIME",
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "통화시간",
          value: "TEL_TIME",
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "콜유형",
          value: "CALL_TY_NM",
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "콜기준여부",
          value: "RDWT_SEND_YN",
          align: " d-none",
          sortable: true,
          width: "100px",
        },
        {
          text: "통화자",
          value: "PROC_CODE_SCLS_NM",
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "상담원명",
          value: "CSLT_NM",
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "고객명",
          value: "CUST_NM",
          align: "center",
          sortable: true,
          width: "120px",
          cellClass: "ellipse ",
        },
        {
          text: "고객주민번호",
          value: "CUST_NO",
          align: " d-none",
          sortable: true,
          width: "150px",
        },
        {
          text: "발신자번호",
          value: "CUST_TEL_NO",
          align: "center",
          sortable: true,
          width: "100px",
        },
        //{ text: "상담사ID", value: "CSLT_ID", align: " d-none", sortable: true, width: "100px", },
        //{ text: "상담유형", value: "CNSL_TYP_CD", align: " d-none", sortable: true, width: "100px", },
        {
          align: ' d-none',
          show: false,
        },
        {
          align: ' d-none',
          show: false,
        },
        // { text: "상담유형2", value: "CNSL_TYP_CD_2", align: " d-none", sortable: true, width: "100px", },
        {
          align: ' d-none',
          show: false,
        },
        //{ text: "상담유형3", value: "CNSL_TYP_CD_3", align: " d-none", sortable: true, width: "100px", },
        {
          align: ' d-none',
          show: false,
        },
        {
          align: ' d-none',
          show: false,
        },
        {
          align: ' d-none',
          show: false,
        },
        {
          align: ' d-none',
          show: false,
        },
        {
          align: ' d-none',
          show: false,
        },
        //{ text: "상담유형4", value: "CNSL_TYP_CD_4", align: " d-none", sortable: true, width: "100px", },
        //{ text: "완료코드", value: "PROC_LCLS_NM", align: " d-none", sortable: true, width: "100px", },
        //{ text: "미완료사유", value: "PROC_MDCLS_NM", align: " d-none", sortable: true, width: "100px", },
        {
          text: "카테고리",
          value: 'CATEGORY_NM',
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "상담채널",
          value: 'CNSL_CHN_1_NM',
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "상담예약",
          value: 'CNSL_BOOK_NM',
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "진료항목",
          value: 'DISA_ITEM_NM',
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "상담유형1",
          value: 'CNSL_TYP_NM',
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "상담유형2",
          value: 'CNSL_TYP_NM_2',
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "상담유형3",
          value: 'CNSL_TYP_NM_3',
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "상담유형4",
          value: 'CNSL_TYP_NM_4',
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "완료여부",
          value: "PROC_NM",
          align: "center",
          sortable: true,
          width: "100px",
        },
        //{ text: "고객전화번호", value: "CUST_TEL_NO", align: " d-none", sortable: true, width: "100px", },
        //{ text: "상담종료일자", value: "CNSL_EOT_DATE", align: " d-none", sortable: true, width: "100px", },
        {
          text: "녹취파일명",
          value: "RDWT_FILE_NM",
          align: "center",
          sortable: true,
          width: "100px",
          cellClass: "ellipse ",
        },
        {
          text: "상담내용",
          value: "CNSL_CNTN",
          align: "center",
          sortable: true,
          width: "100px",
          cellClass: "ellipse ",
        },
        //{ text: "콜유형ID", value: "CALL_TY", align: " d-none", sortable: true, width: "100px", },
        //{ text: "상담종료시간", value: "CNSL_EOT_TIME", align: " d-none", sortable: true, width: "100px", },
        //{ text: "등록일자", value: "REG_DTIM", align: " d-none", sortable: true, width: "100px", },
        //{ text: "완료코드", value: "PROC_CODE_LCLS", align: " d-none", sortable: true, width: "100px", },
        //{ text: "미완료사유", value: "PROC_CODE_MDCLS", align: " d-none", sortable: true, width: "100px", },
        //{ text: "고객주민번호(더미)", value: "CUST_NO", align: " d-none", sortable: true, width: "100px", },
        //{ text: "발신자번호(더미)", value: "SEND_MAN_NO", align: " d-none", sortable: true, width: "100px", },
      ],
      gridDataText: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      totalVisible: 10,
      pagination: { page: 1, rowsPerPage: 500    , sortBy: "", descending: "" },
      textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
      textareaValue: "200자 제한!",
      selectedRow: [],

      CHK_TIME: [
        {
          text: "00시",
          selected: true,
          value: "00",
        },
        {
          text: "01시",
          selected: true,
          value: "01",
        },
        {
          text: "02시",
          selected: true,
          value: "02",
        },
        {
          text: "03시",
          selected: true,
          value: "03",
        },
        {
          text: "04시",
          selected: true,
          value: "04",
        },
        {
          text: "05시",
          selected: true,
          value: "05",
        },
        {
          text: "06시",
          selected: true,
          value: "06",
        },
        {
          text: "07시",
          selected: true,
          value: "07",
        },
        {
          text: "08시",
          selected: true,
          value: "08",
        },
        {
          text: "09시",
          selected: true,
          value: "09",
        },
        {
          text: "10시",
          selected: true,
          value: "10",
        },
        {
          text: "11시",
          selected: true,
          value: "11",
        },
        {
          text: "12시",
          selected: true,
          value: "12",
        },
        {
          text: "13시",
          selected: true,
          value: "13",
        },
        {
          text: "14시",
          selected: true,
          value: "14",
        },
        {
          text: "15시",
          selected: true,
          value: "15",
        },
        {
          text: "16시",
          selected: true,
          value: "16",
        },
        {
          text: "17시",
          selected: true,
          value: "17",
        },
        {
          text: "18시",
          selected: true,
          value: "18",
        },
        {
          text: "19시",
          selected: true,
          value: "19",
        },
        {
          text: "20시",
          selected: true,
          value: "20",
        },
        {
          text: "21시",
          selected: true,
          value: "21",
        },
        {
          text: "22시",
          selected: true,
          value: "22",
        },
        {
          text: "23시",
          selected: true,
          value: "23",
        },
      ],

      CHK_NEWTIME: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],

      CALL_TIME:'',

      SEL_UPDOWN:[{
        CD: 'UP',
        CD_NM:'이상'
      },
      {
        CD: 'DOWN',
        CD_NM:'이하'
      }
      ],
      SEL_NEWUPDOWN:'',

      downGridDataText:[],
      SEL_DATA_NUM:'',

      CNSL_CNTN:'',

      CNSL_CONT:'',
      VIS_PATH:'',
      DISA_ITEM:'',
      CNSL_BOOK:'',
      CNSL_BOOK_DT:'',
      CONS_BOOK:'',
      CONS_BOOK_DT:'',
      OPER_BOOK:'',
      OPER_BOOK_DT:'',
      OPERATION:'',
      CONDITION:'',
      OPERATION_DT:'',
      ETC:'',
      OPERATION_COST:'',
      VIS_BOOK:'',
      VIS_BOOK_DT:'',
      VIS_MANAGE:'',
      OPER_MANAGE:'',

      REC_CALL:'',

      SRH_CNSL_CHN_1:'',
      SRH_CNSL_BOOK:'',
      SRH_DISA_ITEM:'',
      SRH_CONDITION:'',
      SRH_CATEGORY:'',

      CNSL_CHN_1_LIST:[{
        text:'전체',
        value:''
      }],
      CONDITION_LIST:[{
        text:'전체',
        value:''
      }],
      CATEGORY_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_CONT_LIST:[{
        text:'전체',
        value:''
      }],
      VIS_PATH_LIST:[{
        text:'전체',
        value:''
      }],
      DISA_ITEM_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_BOOK_LIST:[{
        text:'전체',
        value:''
      }],
      CONS_BOOK_LIST:[{
        text:'전체',
        value:''
      }],
      OPER_BOOK_LIST:[{
        text:'전체',
        value:''
      }],
      OPERATION_LIST:[{
        text:'전체',
        value:''
      }],
      VIS_BOOK_LIST:[{
        text:'전체',
        value:''
      }],
      VIS_MANAGE_LIST:[{
        text:'전체',
        value:''
      }],
      OPER_MANAGE_LIST:[{
        text:'전체',
        value:''
      }],

      CNSL_RESULT_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_RESULT:'',
      SRH_RESULT:'',

      // 콜관련 회사별 공통코드 조회
      cmmnCdByCus: ["MTC001", "MTC002", "MTC003", "MTC004", "MTC005", "MTC006", "MTC007", "MTC008", "MTC009", "MTC010", "MTC011", "MTC012"],  
      cmmnCdLstByCus: [], 
      
      RDOnly:false,

      TEXT_1:'',
      TEXT_2:'',
      TEXT_3:'',
      TEXT_4:'',
      TEXT_5:'',
      TEXT_6:'',
      TEXT_7:'',
      TEXT_8:'',
      TEXT_9:'',
      TEXT_10:'',

      SEL_1:false,
      SEL_2:false,
      SEL_3:false,
      SEL_4:false,
      SEL_5:false,
      SEL_6:false,
      SEL_7:false,
      SEL_8:false,
      SEL_9:false,
      SEL_10:false,

      MODEL_1:'',
      MODEL_2:'',
      MODEL_3:'',
      MODEL_4:'',
      MODEL_5:'',
      MODEL_6:'',
      MODEL_7:'',
      MODEL_8:'',
      MODEL_9:'',
      MODEL_10:'',

      ITEM_1:'',
      ITEM_2:'',
      ITEM_3:'',
      ITEM_4:'',
      ITEM_5:'',
      ITEM_6:'',
      ITEM_7:'',
      ITEM_8:'',
      ITEM_9:'',
      ITEM_10:'',

      ITEM_TEXT_1: 'text',
      ITEM_TEXT_2: 'text',
      ITEM_TEXT_3: 'text',
      ITEM_TEXT_4: 'text',
      ITEM_TEXT_5: 'text',
      ITEM_TEXT_6: 'text',
      ITEM_TEXT_7: 'text',
      ITEM_TEXT_8: 'text',
      ITEM_TEXT_9: 'text',
      ITEM_TEXT_10: 'text',

      ITEM_VALUE_1: 'value',
      ITEM_VALUE_2: 'value',
      ITEM_VALUE_3: 'value',
      ITEM_VALUE_4: 'value',
      ITEM_VALUE_5: 'value',
      ITEM_VALUE_6: 'value',
      ITEM_VALUE_7: 'value',
      ITEM_VALUE_8: 'value',
      ITEM_VALUE_9: 'value',
      ITEM_VALUE_10: 'value',

      VALUE_1:'',
      VALUE_2:'',
      VALUE_3:'',
      VALUE_4:'',
      VALUE_5:'',
      VALUE_6:'',
      VALUE_7:'',
      VALUE_8:'',
      VALUE_9:'',
      VALUE_10:'',

      CMM_1:'',
      CMM_2:'',
      CMM_3:'',
      CMM_4:'',
      CMM_5:'',
      CMM_6:'',
      CMM_7:'',
      CMM_8:'',
      CMM_9:'',
      CMM_10:'',

      ALLI_1:' d-none',
      ALLI_2:' d-none',
      ALLI_3:' d-none',
      ALLI_4:' d-none',
      ALLI_5:' d-none',
      ALLI_6:' d-none',
      ALLI_7:' d-none',
      ALLI_8:' d-none',
      ALLI_9:' d-none',
      ALLI_10:' d-none',

      CHG_1:'',
      CHG_2:'',
      CHG_3:'',
      CHG_4:'',
      CHG_5:'',
      CHG_6:'',
      CHG_7:'',
      CHG_8:'',
      CHG_9:'',
      CHG_10:'',

      //퓨처누리 변수
      CNSL_LV_1:'',
      CNSL_LV_2:'',
      CNSL_LV_3:'',
      CNSL_LV_4:'',
      CNSL_LV_1_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_LV_2_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_LV_3_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_LV_4_LIST:[{
        text:'전체',
        value:''
      }],

      CNSL_LV_1_DET:'',
      CNSL_LV_2_DET:'',
      CNSL_LV_3_DET:'',
      CNSL_LV_4_DET:'',
      CNSL_LV_1_DET_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_LV_2_DET_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_LV_3_DET_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_LV_4_DET_LIST:[{
        text:'전체',
        value:''
      }],

      cmmnCdFUNU: [
        "FUNU001",
        "FUNU002",
        "FUNU003",
        "FUNU004",
        "FUNU005",
        "FUNU006",
        "FUNU007",
        "FUNU008",
        "FUNU009",
        "FUNU010",
      ],
      cmmnCdLstFUNU: [], // 콜관련공통코드_공통코드 조회 값
    };
  },

  async mounted() {
    //공통코드설정
    let codeName = ['CO001', "PLT007", "PLT025", "CO001", "CO002", "MTC00", "MTC001", "MTC002", "MTC003", "MTC004", "MTC005", "MTC006", "MTC007", "MTC008", "MTC009", "MTC010", "MTC011"];
    this.common_code = await this.mixin_common_code_get_all(codeName);
  
    /* 회사별 공통코드 조회 */
    this.cmmnCdLstByCus = await this.mixin_common_code_get_all(this.cmmnCdByCus);

    /* 퓨처누리 공통코드 조회 */
    this.cmmnCdLstFUNU = await this.mixin_common_code_get_all(this.cmmnCdFUNU);

    this.CNSL_RESULT_LIST = this.mixin_common_code_get(this.common_code, 'CO001','전체');           // 콜상담저장_상담통합 상담결과

    /*
    api.post("/api/palette/common/get-cmm/list", {
        INFO1: "대분류",
        ASP_NEWCUST_KEY: this.selected_channel,
        ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
        ASP_CUST_KEY: this.selected_channel,
        USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
      },
      { 
        head: {
          SERVICE: "palette.common.get-cmm",
          METHOD: "list",
          TYPE: "BIZ_SERVICE",
        } 
      })
      .then((ret) => {
        let LV_DATA = ret.data.DATA;
        let LV_DATA_LENG = LV_DATA.length;
        let i = 0;
        this.CNSL_LV_1_LIST = [{
          text:'전체',
          value:''
        }];
        for(i;i<LV_DATA_LENG;i++){
          this.CNSL_LV_1_LIST.push({
            text: LV_DATA[i].CD_NM,
            value: LV_DATA[i].GROUP_CD
          })
        }
        if(this.selected_channel == 'FUNU'){
          this.ITEM_1 = this.CNSL_LV_1_LIST;
        }
      });
      */

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.selected_channel = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
      this.getCmmCode();
      this.cngSelMenu();
      this.getUserGroup();
    } else {
      this.RDOnly = false;
    }

    this.getConsultTY('*');
  },

  methods: {
    async chnASP() {
      this.srhCnslTypCd = [{ CD: "", CD_NM: "전체" }];
      this.cnsltypcd = "";
      this.srhCnslTypCd2 = [{ CD: "", CD_NM: "전체" }];
      this.cnsltypcd2 = "";
      this.srhCnslTypCd3 = [{ CD: "", CD_NM: "전체" }];
      this.cnsltypcd3 = "";
      this.srhCnslTypCd4 = [{ CD: "", CD_NM: "전체" }];
      this.cnsltypcd4 = "";
      this.getConsultTY('*');
    },
    //시작일자
    startDate(e) {
      this.dates[0] = e;
    },
    //종료일자
    endDate(e) {
      this.dates[1] = e;
    },
    allowedStep: (m) => m % 10 === 0,

    btnUser() {
      var user = {
        ASP_CUST_KEY: this.selected_channel,
        USER_ID: this.userId1,
        USER_NM: this.userNm1,
        USER_NICK: this.userNick1,
      };
      this.userInfo = user;
    },
    setUser() {
      this.userId1 = this.userInfo.USER_ID;
      this.userNm1 = this.userInfo.USER_NM;
      this.userNick1 = this.userInfo.USER_NICK;
    },

    btnCus() {
      var user = {
        ASP_CUST_KEY: this.selected_channel,
        CUST_ID: this.userId,
        PHONE_NUM: this.userPhoneNo.replaceAll("-", ""),
        CUST_NM: this.userNm,
      };
      this.cusInfo = user;
    },
    setCus() {
      this.userId = this.cusInfo.CUST_ID;
      this.userNm = this.cusInfo.CUST_NM;
      this.userPhoneNo = this.cusInfo.CUSTOMER_PHONE_NO;
    },

    showDialog(type) {
      if (this.selected_channel == "" || this.selected_channel == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }

      //팝업 파라미터 설정
      this.popupParams;
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    submitDialog(type) {
      this[`dialog${type}`] = false;
    },

    //상담유형ChangeEvent
    async changeCouselTyeKey(asp_cust_key, spst_coundel_typ_cd, data_type) {
      let CNSL = await this.mixin_counsel_type_code_get(
        this.selected_channel,
        spst_coundel_typ_cd,
        "전체"
      );

      if (data_type === "1") {
        if (this.cnsltypcd == "" || this.cnsltypcd == undefined) {
          this.srhCnslTypCd2 = [{ CD: "", CD_NM: "전체" }];
          this.cnsltypcd2 = "";
        } else {
          this.srhCnslTypCd2 = CNSL;
          this.cnsltypcd2 = "";
        }
        this.srhCnslTypCd3 = [{ CD: "", CD_NM: "전체" }];
        this.cnsltypcd3 = "";
        this.srhCnslTypCd4 = [{ CD: "", CD_NM: "전체" }];
        this.cnsltypcd4 = "";
      } else if (data_type === "2") {
        if (this.cnsltypcd2 == "" || this.cnsltypcd2 == undefined) {
          this.srhCnslTypCd3 = [{ CD: "", CD_NM: "전체" }];
          this.cnsltypcd3 = "";
        } else {
          this.srhCnslTypCd3 = CNSL;
          this.cnsltypcd3 = "";
        }
        this.srhCnslTypCd4 = [{ CD: "", CD_NM: "전체" }];
        this.cnsltypcd4 = "";
      } else if (data_type === "3") {
        if (this.cnsltypcd3 == "" || this.cnsltypcd3 == undefined) {
          this.srhCnslTypCd4 = [{ CD: "", CD_NM: "전체" }];
          this.cnsltypcd4 = "";
        } else {
          this.srhCnslTypCd4 = CNSL;
          this.cnsltypcd4 = "";
        }
      }
    },

    phoneHyphen(number) {
      //핸드폰 번호 형식 만들기
      if (!number) return "";
      var phoneNum = number.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
      return phoneNum;
    },

    dateFormat(date) {
      //날짜 포멧
      if (!date) return "";
      var newDate = "";
      //공백제거
      date = date.replace(/\s/gi, "");

      if (date.length == 8) {
        newDate = date.replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3");
        return newDate;
      } else {
        return date;
      }
    },

    juminFormat(num) {
      //주민 번호 형식 만들기
      var juminNum = num.replace(/-/g, "").replace(/(\d{6})(\d{7})/g, "$1-$2");
      return juminNum;
    },
    timeFormat(time) {
      //접수시간 포맷
      var newTime = "";
      if (!time) return "";

      if (time.length == 6) {
        newTime =
          time.substring(0, 2) +
          ":" +
          time.substring(2, 4) +
          ":" +
          time.substring(4, 6);
        return newTime;
      } else {
        //추후 6자리 외의 데이터가 있을 경우 수정
        return time;
      }
    },

    clear0() {
      this.userId = "";
      this.userNm = "";
      this.userPhoneNo = '';
    },
    clear1() {
      this.userId1 = "";
      this.userNm1 = "";
      this.userNick1 = "";
    },
    yyyyMMdd: function (value) {
      // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
      if (value == "") return "";

      // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
      var js_date = new Date(value);

      // 연도, 월, 일 추출
      var year = js_date.getFullYear();
      var month = js_date.getMonth() + 1;
      var day = js_date.getDate();

      // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      // 최종 포맷 (ex - '2021-10-08')
      return year + "" + month + "" + day;
    },
    //상세정보
    showRowInfo(row, value) {
      this.contactDetail = row;

      this.CNSL_CONT = row.CNSL_CONT;
      this.VIS_PATH = row.VIS_PATH;
      this.DISA_ITEM = row.DISA_ITEM;
      this.CNSL_BOOK = row.CNSL_BOOK;
      this.CNSL_BOOK_DT = row.CNSL_BOOK_DT;
      this.CONS_BOOK = row.CONS_BOOK;
      this.CONS_BOOK_DT = row.CONS_BOOK_DT;
      this.OPER_BOOK = row.OPER_BOOK;
      this.OPER_BOOK_DT = row.OPER_BOOK_DT;
      this.OPERATION = row.OPERATION;
      this.OPERATION_DT = row.OPERATION_DT;
      this.ETC = this.decode(row.ETC);
      this.OPERATION_COST = row.OPERATION_COST;
      this.CONDITION = row.CONDITION;
      this.CNSL_RESULT = row.PROC_CODE_LCLS;

      this.CNSL_CNTN = this.decode(this.contactDetail.CNSL_CNTN);
      this.RDWT_FILE_NM = this.contactDetail.RDWT_FILE_NM;

      this.REC_CALL = process.env.VUE_APP_IPCC_REC_URL + row.RDWT_FILE_NM + ".mp3"

      /*
      this.CNSL_LV_1_DET_LIST = this.CNSL_LV_1_LIST;
      this.CNSL_LV_2_DET_LIST = this.mixin_getLVCode(this.cmmnCdLstFUNU, this.contactDetail.CNSL_TYP_CD, '전체', "중분류", '');
      this.CNSL_LV_3_DET_LIST = this.mixin_getLVCode(this.cmmnCdLstFUNU, this.contactDetail.CNSL_TYP_CD, '전체', "소분류", this.contactDetail.CNSL_TYP_CD_2);
      */
      this.CNSL_LV_1_DET = this.contactDetail.CNSL_TYP_NM
      this.CNSL_LV_2_DET = this.contactDetail.CNSL_TYP_NM_2
      this.CNSL_LV_3_DET = this.contactDetail.CNSL_TYP_NM_3
    },

    isActiveRow(item) {
      const activeClass =
        item.CNSL_HIST_NO === this.contactDetail.CNSL_HIST_NO ? "active" : "";
      return activeClass;
    },

    //엑셀업로드
    exportExcel(table_id, file_name) {
      this.table_id = table_id;
      this.file_name = file_name;
      this.mixin_common_exportExcel();
    },

    async search(DOWN_YN, next) {
      if (this.selected_channel == "" || this.selected_channel == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }
      let channel = this.selected_channel;

      let DATA_NUM = this.pagination.rowsPerPage;
      if(DOWN_YN == 'Y'){
        if (this.gridDataText.length == 0) {
          this.showAlert("상담이력 조회 후 다운로드할 수 있습니다.");
          return;
        }
        if(this.SEL_DATA_NUM != '0' && this.SEL_DATA_NUM != '' && this.SEL_DATA_NUM != undefined){
          DATA_NUM = this.SEL_DATA_NUM;
        }
      } else {
        DATA_NUM = this.pagination.rowsPerPage;
      }

      if(DOWN_YN != 'Y'){
        if (next == 'next'){
          channel = this.search_channel;
        } else {
          channel = this.selected_channel;
          this.gridDataText = [];
          this.search_channel = this.selected_channel;
          this.pagination.page = 1; //페이징 처리 초기화
          this.nextDisabled = true;  //버튼 비활성화
        }
      }

      let head = "";
      let dataa = "";
      this.URLData = "/api/phone/history/inqire/list";
      this.HEADER_SERVICE = "phone.history.inqire";
      this.HEADER_METHOD = "list";
      this.HEADER_TYPE = "BIZ_SERVICE";

      let CHK_GROUP = this.selected_userGroup[0];
      for(let i=1;i<this.selected_userGroup.length;i++){
        CHK_GROUP = CHK_GROUP + "," + this.selected_userGroup[i];
      }
      
      await api
        .post(
          this.URLData,
          {
            ASP_NEWCUST_KEY: channel,
            SEARCH_DT_FROM: this.yyyyMMdd(this.dates[0]),
            SEARCH_DT_TO: this.yyyyMMdd(this.dates[1]),
            CALL_TY: this.calltypcd,
            CNSL_TYP_CD: this.MODEL_1,
            CNSL_TYP_CD_2: this.MODEL_2,
            CNSL_TYP_CD_3: this.MODEL_3,
            CNSL_TYP_CD_4: this.MODEL_4,
            CUST_ID: this.userId,
            CUST_NM: this.userNm,
            CUST_TEL_NO: this.userPhoneNo,
            TM_KIND: CHK_GROUP, //사용자그룹
            CSLT_ID: this.userId1,
            CSLT_NM: this.userNm1,
            CSLT_NICK: this.userNick1,
            PROC_CODE_LCLS: this.SRH_RESULT,
            SEARCH_TIME_0: this.CHK_NEWTIME[0],
            SEARCH_TIME_1: this.CHK_NEWTIME[1],
            SEARCH_TIME_2: this.CHK_NEWTIME[2],
            SEARCH_TIME_3: this.CHK_NEWTIME[3],
            SEARCH_TIME_4: this.CHK_NEWTIME[4],
            SEARCH_TIME_5: this.CHK_NEWTIME[5],
            SEARCH_TIME_6: this.CHK_NEWTIME[6],
            SEARCH_TIME_7: this.CHK_NEWTIME[7],
            SEARCH_TIME_8: this.CHK_NEWTIME[8],
            SEARCH_TIME_9: this.CHK_NEWTIME[9],
            SEARCH_TIME_10: this.CHK_NEWTIME[10],
            SEARCH_TIME_11: this.CHK_NEWTIME[11],
            SEARCH_TIME_12: this.CHK_NEWTIME[12],
            SEARCH_TIME_13: this.CHK_NEWTIME[13],
            SEARCH_TIME_14: this.CHK_NEWTIME[14],
            SEARCH_TIME_15: this.CHK_NEWTIME[15],
            SEARCH_TIME_16: this.CHK_NEWTIME[16],
            SEARCH_TIME_17: this.CHK_NEWTIME[17],
            SEARCH_TIME_18: this.CHK_NEWTIME[18],
            SEARCH_TIME_19: this.CHK_NEWTIME[19],
            SEARCH_TIME_20: this.CHK_NEWTIME[20],
            SEARCH_TIME_21: this.CHK_NEWTIME[21],
            SEARCH_TIME_22: this.CHK_NEWTIME[22],
            SEARCH_TIME_23: this.CHK_NEWTIME[23],
            RANGE: this.SEL_NEWUPDOWN,
            REC_TIME: this.CALL_TIME,

            CATEGORY: this.SRH_CATEGORY,
            CNSL_CHN_1: this.SRH_CNSL_CHN_1,
            CNSL_BOOK: this.SRH_CNSL_BOOK,
            DISA_ITEM: this.SRH_DISA_ITEM,
            CONDITION: this.SRH_CONDITION,

            VALUE_1: this.VALUE_1,
            VALUE_2: this.VALUE_2,
            VALUE_3: this.VALUE_3,
            VALUE_4: this.VALUE_4,
            VALUE_5: this.VALUE_5,
            VALUE_6: this.VALUE_6,
            VALUE_7: this.VALUE_7,
            VALUE_8: this.VALUE_8,
            VALUE_9: this.VALUE_9,
            VALUE_10: this.VALUE_10,

            MODEL_1: this.MODEL_1,
            MODEL_2: this.MODEL_2,
            MODEL_3: this.MODEL_3,
            MODEL_4: this.MODEL_4,
            MODEL_5: this.MODEL_5,
            MODEL_6: this.MODEL_6,
            MODEL_7: this.MODEL_7,
            MODEL_8: this.MODEL_8,
            MODEL_9: this.MODEL_9,
            MODEL_10: this.MODEL_10,

            CMM_1: this.CMM_1,
            CMM_2: this.CMM_2,
            CMM_3: this.CMM_3,
            CMM_4: this.CMM_4,
            CMM_5: this.CMM_5,
            CMM_6: this.CMM_6,
            CMM_7: this.CMM_7,
            CMM_8: this.CMM_8,
            CMM_9: this.CMM_9,
            CMM_10: this.CMM_10,
          },
          {
            head: {
              SERVICE: this.HEADER_SERVICE,
              METHOD: this.HEADER_METHOD,
              TYPE: this.HEADER_TYPE,
              ROW_CNT: DATA_NUM,
              PAGES_CNT: DOWN_YN == 'Y'? '1' : this.pagination.page,
              PAGING: 'Y',
            },
          }
        )
        .then((response) => {
          this.contactDetail = [];

          if (this.selectedRow != "") {
            this.activeRow(this.selectedRow, false);
            this.selectedRow = "";
          }

          let jsonObj = JSON.parse(JSON.stringify(response.data.DATA));

          if(DOWN_YN == 'Y'){
            this.downGridDataText = jsonObj;
          } else {
            // this.gridDataText = jsonObj;            
            this.gridDataText = [...this.gridDataText, ...jsonObj]
            //다음
            console.log('response.data.HEADER.next=',response.data.HEADER.next)
            //request에 보낸 head.PAGES_CNT와 head.ROW_CNT을 곱한값과 response.data.HEADER의 HEADER.TOT_COUNT와 비교하여 다음 조회건이 있는지 확인한다
            if(response.data.HEADER.next !== null && response.data.HEADER.next !== undefined){
              if(response.data.HEADER.next === true){
                this.nextDisabled = false //버튼 활성화
              }else{
                this.nextDisabled = true  //버튼 비활성화
              }
            }

            //이부분은 체크해볼것
            this.pagination.page += 1 
            //나중에 그리드를 초기화시에는 this.pagination.page를 1로 바꿔줘야 함
          }

          head = JSON.stringify(response.data.HEADER);
          dataa = JSON.stringify(response.data.DATA);
        })
        .catch((err) => {
          alert(err);
        });
      this.HEADERResult = head;
      this.DATAResult = dataa;

      if(DOWN_YN == 'Y'){
        this.exportExcel('downTable', '상담이력조회 목록');
      }
    },

    showAlert(msg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: msg,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },

    chgChkTime(){
      let TimeArray = this.CHK_NEWTIME;
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
      for(let i = 0; i < TimeArray.length; i++){
        if(TimeArray[i] != "" || TimeArray[i] != null){
          let m = parseInt(TimeArray[i]);
          this.CHK_NEWTIME[m] = TimeArray[i];
        }
      }
     this.CHK_NEWTIME.splice(24,1);
    },

    removeDropTime(item) {
      this.CHK_NEWTIME.splice(parseInt(item.value), 1);

      let TimeArray = this.CHK_NEWTIME;
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
      for(let i = 0; i < TimeArray.length; i++){
        if(TimeArray[i] != "" || TimeArray[i] != null){
          let m = parseInt(TimeArray[i]);
          this.CHK_NEWTIME[m] = TimeArray[i];
        }
      }

      this.chkNoTime();
    },

    allClick() {
      this.CHK_NEWTIME = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ];
    },

    allCloseClick() {
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
    },

    async selectTime() {
      if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }
      await api
        .post(
          "api/statistics/chat/common/start-job-time/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "statistics.chat.common.start-job-time",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          var ST_TIME = response.data.DATA[0].WORK_START_TIME;

          var ST_HOUR = ST_TIME.substr(0, 2);
          this.ST_JOB_TIME = ST_HOUR;
        })
        .catch((err) => {
          alert(err);
        });

      await api
        .post(
          "api/statistics/chat/common/end-job-time/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "statistics.chat.common.end-job-time",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          var END_TIME = response.data.DATA[0].WORK_END_TIME;

          var END_HOUR = END_TIME.substr(0, 2);
          this.END_JOB_TIME = END_HOUR;
        })
        .catch((err) => {
          alert(err);
        });

      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];

      for (
        var i = parseInt(this.ST_JOB_TIME);
        i <= parseInt(this.END_JOB_TIME);
        i++
      ) {
        if (i < 10) {
          let DB_TIME = "0" + i;
          this.CHK_NEWTIME[i] = DB_TIME;
        } else {
          this.CHK_NEWTIME[i] = i + "";
        }
      }
    },

    selectTurn() {
      var NEWTIME = [];

      for (var i = 0; i < 24; i++) {
        NEWTIME[i] = this.CHK_NEWTIME[i];
        if (i < 10) {
          if (NEWTIME[i] != "") {
            NEWTIME[i] = "";
          } else if (NEWTIME[i] == "") {
            let DB_TIME = "0" + i;
            NEWTIME[i] = DB_TIME;
          }
        } else {
          if (NEWTIME[i] != "") {
            NEWTIME[i] = "";
          } else if (NEWTIME[i] == "") {
            NEWTIME[i] = i + "";
          }
        }
      }
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
      this.CHK_NEWTIME = NEWTIME;

    },

    getCmmCode(){
      this.CNSL_CONT_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC001','전체',this.selected_channel);
      this.VIS_PATH_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC002','전체',this.selected_channel);
      this.DISA_ITEM_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC003','전체',this.selected_channel);
      this.CNSL_BOOK_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC004','전체',this.selected_channel);
      this.CONDITION_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC005','전체',this.selected_channel);
      this.CONS_BOOK_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC006','전체',this.selected_channel);
      this.VIS_BOOK_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC007','전체',this.selected_channel);
      this.OPER_BOOK_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC008','전체',this.selected_channel);
      this.CATEGORY_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC009','전체',this.selected_channel);
      this.OPERATION_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC010','전체',this.selected_channel);
      this.OPER_MANAGE_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC010','전체',this.selected_channel);  //리스트 추가해야함 (공통코드인지 다른 리스트인지)
      this.VIS_MANAGE_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC010','전체',this.selected_channel);   //리스트 추가해야함 (공통코드인지 다른 리스트인지)
      this.MANAGE_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC010','전체',this.selected_channel);       //리스트 추가해야함 (공통코드인지 다른 리스트인지)
      this.CNSL_CHN_1_LIST = this.mixin_common_code_byCust(this.cmmnCdLstByCus, 'MTC012','전체',this.selected_channel);   
      //this.CNSL_CHN_2_LIST = this.common_code_byCust(this.cmmnCdLstByCus, 'MTC013','전체',this.selected_channel);
    },

    chgCnslLv(CHK){
      if(CHK == '중분류'){
        this.CNSL_LV_2_LIST=[];
        this.ITEM_2=[];
        this.CNSL_LV_2='';
        this.MODEL_2='';
        this.CNSL_LV_3_LIST=[];
        this.ITEM_3=[{text:'전체',value:''}];
        this.CNSL_LV_3='';
        this.MODEL_3='';
        this.CNSL_LV_2_LIST = this.mixin_getLVCode(this.cmmnCdLstFUNU, this.MODEL_1, '전체', CHK, '');
        this.ITEM_2 = this.CNSL_LV_2_LIST;
      } else if (CHK == '소분류'){
        this.CNSL_LV_3_LIST=[];
        this.ITEM_3=[];
        this.CNSL_LV_3='';
        this.MODEL_3='';
        this.CNSL_LV_3_LIST = this.mixin_getLVCode(this.cmmnCdLstFUNU, this.MODEL_1, '전체', CHK, this.MODEL_2);
        this.ITEM_3 = this.CNSL_LV_3_LIST;
      }
    },
    
    cngSelMenu(){
      if(this.selected_channel == 'MOMO' || this.selected_channel == 'DIET' || this.selected_channel == 'LITING' || this.selected_channel == 'PLAN'){
        this.SEL_1 = true;
        this.SEL_2 = true;
        this.SEL_3 = true;
        this.SEL_4 = true;

        this.SEL_8 = false;

        this.TEXT_1 = '상담유형1';
        this.TEXT_2 = '상담유형2';
        this.TEXT_3 = '상담유형3';
        this.TEXT_4 = '상담유형4';
        this.ITEM_1 = this.CNSL_LV_1_LIST;
        this.ITEM_2 = this.CNSL_LV_2_LIST;
        this.ITEM_3 = this.CNSL_LV_3_LIST;
        this.ITEM_4 = this.CNSL_LV_4_LIST;
        this.ITEM_TEXT_1 = 'CD_NM';
        this.ITEM_TEXT_2 = 'CD_NM';
        this.ITEM_TEXT_3 = 'CD_NM';
        this.ITEM_TEXT_4 = 'CD_NM';
        this.ITEM_VALUE_1 = 'CD';
        this.ITEM_VALUE_2 = 'CD';
        this.ITEM_VALUE_3 = 'CD';
        this.ITEM_VALUE_4 = 'CD';
        this.MODEL_1 = this.CNSL_LV_1;
        this.MODEL_2 = this.CNSL_LV_2;
        this.MODEL_3 = this.CNSL_LV_3;
        this.MODEL_4 = this.CNSL_LV_4;

        this.CHG_1 = "";
        this.CHG_2 = "";
        this.SEL_5 = true;
        this.SEL_6 = true;
        this.SEL_7 = true;
        this.SEL_9 = true;
        this.SEL_10 = true;
        this.TEXT_5 = '카테고리';
        this.TEXT_6 = '상담채널';
        this.TEXT_7 = '문의유형';
        this.TEXT_9 = '진료항목';
        this.TEXT_10 = '상태';
        this.ITEM_5 = this.CATEGORY_LIST;
        this.ITEM_6 = this.CNSL_CHN_1_LIST;
        this.ITEM_7 = this.CNSL_BOOK_LIST;
        this.ITEM_9 = this.DISA_ITEM_LIST;
        this.ITEM_10 = this.CONDITION_LIST;
        this.ITEM_TEXT_5 = 'text';
        this.ITEM_TEXT_6 = 'text';
        this.ITEM_TEXT_7 = 'text';
        this.ITEM_TEXT_9 = 'text';
        this.ITEM_TEXT_10 = 'text';
        this.ITEM_VALUE_4 = 'value';
        this.ITEM_VALUE_5 = 'value';
        this.ITEM_VALUE_6 = 'value';
        this.ITEM_VALUE_7 = 'value';
        this.ITEM_VALUE_9 = 'value';
        this.ITEM_VALUE_10 = 'value';
        this.MODEL_5 = this.SRH_CATEGORY;
        this.MODEL_6 = this.SRH_CNSL_CHN_1;
        this.MODEL_7 = this.SRH_CNSL_BOOK;
        this.MODEL_9 = this.SRH_DISA_ITEM;
        this.MODEL_10 = this.SRH_CONDITION;
        this.VALUE_5 = 'CATEGORY';
        this.VALUE_6 = 'CNSL_CHN_1';
        this.VALUE_7 = 'CNSL_BOOK';
        this.VALUE_9 = 'DISA_ITEM';
        this.VALUE_10 = 'CONDITION';
        this.CMM_5 = 'MTC009';
        this.CMM_6 = 'MTC012';
        this.CMM_7 = 'MTC004';
        this.CMM_9 = 'MTC003';
        this.CMM_10 = 'MTC005';
      } else if(this.selected_channel == 'FUNU'){
        console.log("this.CNSL_LV_1_LIST", this.CNSL_LV_1_LIST);
        this.SEL_1 = true;
        this.SEL_2 = true;
        this.SEL_3 = true;
        this.SEL_4 = false;
        this.SEL_5 = false;
        this.SEL_6 = false;
        this.SEL_7 = false;
        this.SEL_8 = false;
        this.SEL_9 = false;
        this.SEL_10 = false;
        this.TEXT_1 = '상담유형1';
        this.TEXT_2 = '상담유형2';
        this.TEXT_3 = '상담유형3';
        this.ITEM_1 = this.CNSL_LV_1_LIST;
        this.ITEM_2 = this.CNSL_LV_2_LIST;
        this.ITEM_3 = this.CNSL_LV_3_LIST;
        this.ITEM_TEXT_1 = 'CD_NM';
        this.ITEM_TEXT_2 = 'CD_NM';
        this.ITEM_TEXT_3 = 'CD_NM';
        this.ITEM_VALUE_1 = 'CD';
        this.ITEM_VALUE_2 = 'CD';
        this.ITEM_VALUE_3 = 'CD';
        this.MODEL_1 = this.CNSL_LV_1;
        this.MODEL_2 = this.CNSL_LV_2;
        this.MODEL_3 = this.CNSL_LV_3;
        this.CHG_1 = "중분류";
        this.CHG_2 = "소분류";
      } else {
        this.SEL_1 = false;
        this.SEL_2 = false;
        this.SEL_3 = false;
        this.SEL_4 = false;
        this.SEL_5 = false;
        this.SEL_6 = false;
        this.SEL_7 = false;
        this.SEL_8 = false;
        this.SEL_9 = false;
        this.SEL_10 = false;
      }
    },

    //
    //상담유형 사용
    //상담유형 select 불러오기
    getConsultTY(consult){
      console.log("here!!",this.selected_channel);
      var box = '';
      this.CNSL_LV_1 = this.MODEL_1;
      this.CNSL_LV_2 = this.MODEL_2;
      this.CNSL_LV_3 = this.MODEL_3;
      this.CNSL_LV_4 = this.MODEL_4;
      if(consult == "*") {
        this.CNSL_LV_1_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_1 = '';
        this.CNSL_LV_2_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_2 = '';
        this.CNSL_LV_3_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_3 = '';
        this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_4 = '';
        box = consult;
      } else if(consult == "1"){
        this.CNSL_LV_2_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_2 = '';
        this.CNSL_LV_3_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_3 = '';
        this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_4 = '';
        box = this.CNSL_LV_1;
      } else if(consult == "2"){
        this.CNSL_LV_3_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_3 = '';
        this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_4 = '';
        box = this.CNSL_LV_2;
      } else if(consult == "3"){
        this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_4 = '';
        box = this.CNSL_LV_3;
      }
      api.post("api/setting/consulttype/manage/inqire",   //api url입력
      {
        ASP_NEWCUST_KEY: this.selected_channel,
        SPST_CNSL_TYP_CD: box,
        USE_YN: "Y",
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "api.palette-main.aspcustkey",
        "METHOD" : "inqire",
        "TYPE" : "BIZ_SERVICE",
      }})
      .then((response) => {
        console.log("상담 시작", response.data);
        if(consult == "*") {
          this.CNSL_LV_1_LIST = [...this.CNSL_LV_1_LIST, ...response.data.DATA];
        } else if(consult == "1"){
          if (this.CNSL_LV_1 == ''){
            this.CNSL_LV_2_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_2 = '';
            this.CNSL_LV_3_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_3 = '';
            this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_4 = '';
          } else {
            this.CNSL_LV_2_LIST = [...this.CNSL_LV_2_LIST, ...response.data.DATA];
          }
        } else if(consult == "2"){
          if (this.CNSL_LV_2 == ''){
            this.CNSL_LV_3_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_3 = '';
            this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_4 = '';
          }else {
            this.CNSL_LV_3_LIST = [...this.CNSL_LV_3_LIST, ...response.data.DATA];
          }
        } else if(consult == "3"){
          if (this.CNSL_LV_3 == ''){
            this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_4 = '';
          }else {
            this.CNSL_LV_4_LIST = [...this.CNSL_LV_4_LIST, ...response.data.DATA];
          }
        }
        this.ITEM_1 = this.CNSL_LV_1_LIST;
        this.ITEM_2 = this.CNSL_LV_2_LIST;
        this.ITEM_3 = this.CNSL_LV_3_LIST;
        this.ITEM_4 = this.CNSL_LV_4_LIST;
      })
      .catch((err) => {
        alert(err);
      })
    },

    //상담유형 사용
    //

    //소속정보를 가져와 사용자 그룹 셀렉트 박스 만들기
    async getUserGroup() {
      this.userGroup = await this.mixin_attr_get(this.selected_channel, "B", "");
      let userGroupLeng = this.userGroup.length;
      let i = 0;
      this.selected_userGroup = [];
      for (i;i<userGroupLeng;i++){
        this.selected_userGroup[i] = this.userGroup[i].CD;
      }
    },

    removeDropGroup(index) {
      this.selected_userGroup.splice(index, 1);
    },

    /**
     * XSS Decode
     */
    decode(strText) {

      //값존재유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }
      strText = strText.toString();

      //문자열 길이가 4이상일 경우만 처리
      if (strText.length <= 3) {
        return strText;
      }

      strText = strText.replaceAll("&lt;", "<");
      strText = strText.replaceAll("&gt;", ">");
      strText = strText.replaceAll("&#40;", "(");
      strText = strText.replaceAll("&#41;", ")");
      strText = strText.replaceAll("&#91;", "[");
      strText = strText.replaceAll("&#93;", "]");
      strText = strText.replaceAll("&amp;", "&");
      strText = strText.replaceAll("&quot;", '"');
      strText = strText.replaceAll("&apos;", "'");
      strText = strText.replaceAll("<br>", "\r");
      strText = strText.replaceAll("<p>", "\n");

      return strText;
    },
  },

  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    ...mapGetters({
      //store에 저장된 사용자의 company 정보를 가져온다
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
    popupParams() {
      return (this.params = {
        userId: this.userId,
        userNm: this.userNm,
        asp_cust_key: this.asp_cust_key,
      });
    },
  },
};
</script>

<style></style>
